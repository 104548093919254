import React from 'react';

class Contact extends React.Component {
    render() {
        return (
            <div id='develuga-contact' className='dvg-contact'>
                <div className='dvg-contact__wrap'>Contact</div>
            </div>
        )
    }
}

export default Contact;