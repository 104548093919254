import React from 'react';

class Cards extends React.Component {
    render() {
        return (
            <div id='develuga-cards' className='dvg-cards'>
                <div className='dvg-cards__wrap'>Cards</div>
            </div>
        )
    }
}

export default Cards;